import {
  required,
  maxLength
} from 'vuelidate/lib/validators';

const textNumSpecial = (value) => /^[a-zA-Z0-9 \u00f1\u00d1\-]+$/g.test(value);

export default () => {
  return {
    tapa: {
      HatchCoverName: { required, textNumSpecial, maxLength: maxLength(100) }
    },
    localizacion: { required },
    tpCover: { required }
  }
}