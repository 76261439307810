<template>
  <CCard>
    <loading-overlay :active="isLoadingGeneral" :is-full-page="true" loader="bars" />
    <CCardHeader class="text-center dark text-white">
      <CRow>
        <CCol sm="8" class="d-flex ">{{titulo}}</CCol>
          <CCol sm="4" class="d-flex justify-content-end">
            <CButton size="sm" 
              @click="tablaCollapse = !tablaCollapse" 
              v-c-tooltip="{
                content: $t('label.dataTable'),
                placement: 'top-end'
              }"
            >
              {{ cambiaTexto }}
            <CIcon :name="cambiaIcono"/>
          </CButton>
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="10">
          <CInput
            v-model.trim="$v.tapa.TpHatchCoverNameEn.$model"
            v-uppercase
            :horizontal="{ label: 'col-sm-4', input: 'col-sm-7'}"
            :invalid-feedback="$t('label.required')+$t('label.onlyText')"
            :is-valid="hasError($v.tapa.TpHatchCoverNameEn)"
            size="sm"
            :label="$t('label.hatchCoverName')+' (EN)'"
            :placeholder="$t('label.hatchCover')+' (EN)'"
            addLabelClasses="required text-right"
          />
          <CInput
            v-model.trim="$v.tapa.TpHatchCoverNameEs.$model"
            v-uppercase
            :horizontal="{ label: 'col-sm-4', input: 'col-sm-7'}"
            :invalid-feedback="$t('label.required')+$t('label.onlyText')"
            :is-valid="hasError($v.tapa.TpHatchCoverNameEs)"
            size="sm"
            :label="$t('label.hatchCoverName')+' (ES)'"
            :placeholder="$t('label.hatchCover')+' (ES)'"
            addLabelClasses="required text-right"
          />
        </CCol>
        <CCol sm="2">
          <CCol sm="12" class="d-flex justify-content-end align-items-start">
            <CRow>
            <CButton
              v-if="!Edit"
              size="sm"
              shape="square"
              color="add"
              class="d-flex align-items-center mr-2"
              :disabled="isSubmit"
              v-c-tooltip="{
                content: $t('label.aggregate'),
                placement: 'top-end'
              }"
              @click="submit"
            >
              <div>
                <CIcon name="checkAlt"/>
              </div>
            </CButton>
            <CButton
              v-else
              size="sm"
              shape="square"
              color="add"
              class="d-flex align-items-center mr-2"
              :disabled="isSubmit"
              v-c-tooltip="{
                content: $t('label.aggregate'),
                placement: 'top-end'
              }"
              @click="statusConfirmation"
            >
              <div>
                <CIcon name="checkAlt"/>
              </div>
            </CButton>
            <CButton
              size="sm"
              shape="square"
              color="wipe"
              :disabled="isSubmit"
              class="d-flex align-items-center"
              v-c-tooltip="{
                content: $t('label.clearFields'),
                placement: 'top-end'
              }"
              @click.stop="resetForm"
            >
              <CIcon name="cil-brush-alt" />
            </CButton>
          </CRow>
          </CCol>
          
        </CCol>
        <CCol sm="10" v-if="Edit">
          <CSelect
            :value.sync="tapa.Status"
            :is-valid="statusSelectColor"
            :horizontal="{ label: 'col-sm-4', input: 'col-sm-7'}"
            size="sm"
            :label="$t('label.status')"
            :options="statusOptions"
            class="mt-1"
            addLabelClasses="required text-right"
          />
        </CCol>
      </CRow>
      <CCollapse :show="tablaCollapse" class="mt-2">
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :fields="fields"
            :items="computedHatchCoverList"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            hover
            small
            sorter
          >
            <template #Status="{item}">
              <td class="center-cell">
                <CBadge :color="getBadge(item.Status)">
                  {{ $t('label.'+item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #Detalle="{item}">
              <td class="center-cell">
                <CButton color="edit" class="mr-1" size="sm" 
                  v-c-tooltip="{
                    content: $t('label.edit')+' '+$t('label.hatchCoverType'),
                    placement: 'top-end'
                  }"
                  @click="setForm(item)"
                >
                  <CIcon name="pencil" />
                </CButton>
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CCollapse>
    </CCardBody>
  </CCard>
</template>

<script>
import TpHatchFormValidations from '@/_validations/buque/TpHatchFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';
import { DateFormater, tableTextTranslatedHelpers } from '@/_helpers/funciones';

//Data
function data() {
  return {
    isSubmit: false,
    tablaCollapse: false,
    HatchCover: [],
    Edit: false,
    tapa: {
      TpHatchCoverId: '',
      TpHatchCoverNameEn: '',
      TpHatchCoverNameEs: '',
      Status: 1
    },
    isLoadingGeneral: false,
  }
}

//Method
function tableText(){
  return tableTextTranslatedHelpers(this);
}
function fields(){
  return [
    { key: 'Nro', label: '#', filter: false, _style: 'width:1%; text-align:center;' },
    { key: 'TpHatchCoverNameEn', label: this.$t('label.hatchCover')+' (EN)', _style:'width:22%', _classes: 'text-uppercase' },
    { key: 'TpHatchCoverNameEs', label: this.$t('label.hatchCover')+' (Es)', _style:'width:22%', _classes: 'text-uppercase' },
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:15%;', _classes: 'text-uppercase' },
    { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width: 15%; text-align:center;', _classes:'text-center'},
    { key: 'Status', label: this.$t('label.status'), _style: 'width: 15%;', _classes:'text-center'},
    { 
      key: 'Detalle',
      label: '',
      sorter: false,
      filter: false,
      _style:'width:5%; min-width:45px;',
      _classes: 'text-center'
    }
  ];
}

function submit() {
  try {
    this.isLoadingGeneral = true;
    this.isSubmit = true;
    this.$v.$touch();
    if (this.$v.$error) {
        this.isLoadingGeneral = false;
        this.isSubmit = false;
        throw this.$t('label.errorsPleaseCheck');
    }
    let metodo = this.Edit ? 'PUT':'POST';
    let ruta = this.Edit ? 'TpHatchCover-update' : 'TpHatchCover-insert';
    let TpHatchCoverJson = this.Edit
      ? {
          TpHatchCoverId: this.tapa.TpHatchCoverId,
          TpHatchCoverNameEn: this.tapa.TpHatchCoverNameEn,
          TpHatchCoverNameEs: this.tapa.TpHatchCoverNameEs,
          Status: this.tapa.Status
        }
      : { 
          TpHatchCoverNameEn: this.tapa.TpHatchCoverNameEn,
          TpHatchCoverNameEs: this.tapa.TpHatchCoverNameEs 
        };

    this.$http.ejecutar(metodo, ruta, TpHatchCoverJson, { root: 'TpHatchCoverJson' })
    .then(response => {
      
      this.isSubmit = false;
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.resetForm();
      this.getHatchCoverList();
      this.$emit('set-Type-list');
      this.isLoadingGeneral = false;
    }).catch(err => {
      this.isLoadingGeneral = false;
      this.isSubmit = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
  } catch (e) {
    this.isSubmit = false;
    this.isLoadingGeneral = false;
    this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
      });
  }
}

function hasError(field){
  return field.$dirty ? !field.$error : null;
}
function resetForm() {
  this.Edit = false;
  this.tapa.TpHatchCoverId = '';
  this.tapa.TpHatchCoverNameEn = '';
  this.tapa.TpHatchCoverNameEs = '';
  this.tapa.Status = 1;
  this.$v.$reset();
}
function setForm(newTapa) {
  this.Edit = true;
  this.tapa.TpHatchCoverId = newTapa.TpHatchCoverId;
  this.tapa.TpHatchCoverNameEn = newTapa.TpHatchCoverNameEn;
  this.tapa.TpHatchCoverNameEs = newTapa.TpHatchCoverNameEs;
  this.tapa.Status = newTapa.FgActTpHatchCover ? 1 : 0;
  this.StatusOrigin = newTapa.FgActTpHatchCover ? 1 : 0;
  this.$v.$touch();
}

function statusConfirmation(){
  let status = this.StatusOrigin?1:0;
  if(this.tapa.Status === status)
    this.submit();
  else{
    this.$swal.fire({
      text: `${this.$t('label.changeStatusQuestion')+' '} ${this.tapa.TpHatchCoverNameEn} ${this.$t('label.and')} ${this.tapa.TpHatchCoverNameEs}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#42AA91',
      cancelButtonColor: '#E1373F',
      confirmButtonText: this.$t('button.confirm'),
      cancelButtonText: this.$t('button.cancel')
    }).then((result) => {
      if (result.isConfirmed) {
        this.submit();
      }
    });
  }
}

function getHatchCoverList() {
  let listado = Array;
  this.isLoadingGeneral=true;
  this.items = [];
  this.items.length = 0;

  this.$http.get('TpHatchCover-list')
  .then(response => {
    listado = [...response.data.data];
    let nulo = String(listado[0].Json);
    if (nulo=="null" || listado ==''){
      return false;
    } 
    this.HatchCover = listado;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingGeneral= false;
  });
}

//Computed
function titulo() {
  let _lang = this.$i18n.locale;
  let title = _lang=='en' ? this.tapa.TpHatchCoverNameEn : this.tapa.TpHatchCoverNameEs;
  return this.Edit ? this.$t('label.edit')+' '+this.$t('label.hatchCoverType')+': '+title:this.$t('label.nuevo')+''+this.$t('label.hatchCoverType');
}
function cambiaTexto() {
  return this.tablaCollapse ? this.$t("label.close") : this.$t("label.toView");
}
function cambiaIcono() {
  return this.tablaCollapse ? 'cil-chevron-top-alt' : 'cil-chevron-bottom-alt';
}
function statusOptions() {
  return [
    {
      value: 1,
      label: this.$t('label.ACTIVO')
    },
    {
      value: 0,
      label: this.$t('label.INACTIVO')
    }
  ]
}
function statusSelectColor(){
  return this.tapa.Status === 1;
}
function computedHatchCoverList() {
  if (this.HatchCover ==[]) return this.HatchCover = [];
  if (this.HatchCover.length > 0) {
    return this.HatchCover.map((item) => {
      return {
        ...item,
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
        };
      });
    }
  }

export default {
  name:  'tp-hatch-collapse',
  mixins: [GeneralMixin],
  props: {
    showCollapse: {
      type: Boolean,
      default: false
    }
  },
  data,
  validations: TpHatchFormValidations,
  directives: UpperCase,
  methods: {
    submit,
    hasError,
    resetForm,
    setForm,
    statusConfirmation,
    getHatchCoverList,
    statusSelectColor,
  },
  computed: {
    computedHatchCoverList,
    tableText,
    fields,
    titulo,
    cambiaTexto,
    cambiaIcono,
    statusOptions,
  },
  watch: {
    showCollapse: function(val){
      if(val==false){
        this.resetForm();
      }else{
        this.getHatchCoverList();
      }
    }
  }
}
</script>

<style scoped>

</style>