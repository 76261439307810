import {
  required,
  maxLength
} from 'vuelidate/lib/validators';
import { alphaSpecials } from '../funciones';

export default () => {
  return {
    tapa: {
      TpHatchCoverNameEs: { required, alphaSpecials, maxLength: maxLength(100) },
      TpHatchCoverNameEn: { required, alphaSpecials, maxLength: maxLength(100) },
    }
  }
}