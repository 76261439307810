var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',[_c('loading-overlay',{attrs:{"active":_vm.isLoadingGeneral,"is-full-page":true,"loader":"bars"}}),_c('CCardHeader',{staticClass:"text-center dark text-white"},[_c('CRow',[_c('CCol',{staticClass:"d-flex",attrs:{"sm":"8"}},[_vm._v(_vm._s(_vm.titulo))]),_c('CCol',{staticClass:"d-flex justify-content-end",attrs:{"sm":"4"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.dataTable'),
              placement: 'top-end'
            }),expression:"{\n              content: $t('label.dataTable'),\n              placement: 'top-end'\n            }"}],attrs:{"size":"sm"},on:{"click":function($event){_vm.tablaCollapse = !_vm.tablaCollapse}}},[_vm._v(" "+_vm._s(_vm.cambiaTexto)+" "),_c('CIcon',{attrs:{"name":_vm.cambiaIcono}})],1)],1)],1)],1),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"10"}},[_c('CInput',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"horizontal":{ label: 'col-sm-4', input: 'col-sm-7'},"invalid-feedback":_vm.$t('label.required')+_vm.$t('label.onlyText'),"is-valid":_vm.hasError(_vm.$v.tapa.TpHatchCoverNameEn),"size":"sm","label":_vm.$t('label.hatchCoverName')+' (EN)',"placeholder":_vm.$t('label.hatchCover')+' (EN)',"addLabelClasses":"required text-right"},model:{value:(_vm.$v.tapa.TpHatchCoverNameEn.$model),callback:function ($$v) {_vm.$set(_vm.$v.tapa.TpHatchCoverNameEn, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.tapa.TpHatchCoverNameEn.$model"}}),_c('CInput',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"horizontal":{ label: 'col-sm-4', input: 'col-sm-7'},"invalid-feedback":_vm.$t('label.required')+_vm.$t('label.onlyText'),"is-valid":_vm.hasError(_vm.$v.tapa.TpHatchCoverNameEs),"size":"sm","label":_vm.$t('label.hatchCoverName')+' (ES)',"placeholder":_vm.$t('label.hatchCover')+' (ES)',"addLabelClasses":"required text-right"},model:{value:(_vm.$v.tapa.TpHatchCoverNameEs.$model),callback:function ($$v) {_vm.$set(_vm.$v.tapa.TpHatchCoverNameEs, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.tapa.TpHatchCoverNameEs.$model"}})],1),_c('CCol',{attrs:{"sm":"2"}},[_c('CCol',{staticClass:"d-flex justify-content-end align-items-start",attrs:{"sm":"12"}},[_c('CRow',[(!_vm.Edit)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.aggregate'),
              placement: 'top-end'
            }),expression:"{\n              content: $t('label.aggregate'),\n              placement: 'top-end'\n            }"}],staticClass:"d-flex align-items-center mr-2",attrs:{"size":"sm","shape":"square","color":"add","disabled":_vm.isSubmit},on:{"click":_vm.submit}},[_c('div',[_c('CIcon',{attrs:{"name":"checkAlt"}})],1)]):_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.aggregate'),
              placement: 'top-end'
            }),expression:"{\n              content: $t('label.aggregate'),\n              placement: 'top-end'\n            }"}],staticClass:"d-flex align-items-center mr-2",attrs:{"size":"sm","shape":"square","color":"add","disabled":_vm.isSubmit},on:{"click":_vm.statusConfirmation}},[_c('div',[_c('CIcon',{attrs:{"name":"checkAlt"}})],1)]),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.clearFields'),
              placement: 'top-end'
            }),expression:"{\n              content: $t('label.clearFields'),\n              placement: 'top-end'\n            }"}],staticClass:"d-flex align-items-center",attrs:{"size":"sm","shape":"square","color":"wipe","disabled":_vm.isSubmit},on:{"click":function($event){$event.stopPropagation();return _vm.resetForm.apply(null, arguments)}}},[_c('CIcon',{attrs:{"name":"cil-brush-alt"}})],1)],1)],1)],1),(_vm.Edit)?_c('CCol',{attrs:{"sm":"10"}},[_c('CSelect',{staticClass:"mt-1",attrs:{"value":_vm.tapa.Status,"is-valid":_vm.statusSelectColor,"horizontal":{ label: 'col-sm-4', input: 'col-sm-7'},"size":"sm","label":_vm.$t('label.status'),"options":_vm.statusOptions,"addLabelClasses":"required text-right"},on:{"update:value":function($event){return _vm.$set(_vm.tapa, "Status", $event)}}})],1):_vm._e()],1),_c('CCollapse',{staticClass:"mt-2",attrs:{"show":_vm.tablaCollapse}},[_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"fields":_vm.fields,"items":_vm.computedHatchCoverList,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"hover":"","small":"","sorter":""},scopedSlots:_vm._u([{key:"Status",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"Detalle",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.edit')+' '+_vm.$t('label.hatchCoverType'),
                  placement: 'top-end'
                }),expression:"{\n                  content: $t('label.edit')+' '+$t('label.hatchCoverType'),\n                  placement: 'top-end'\n                }"}],staticClass:"mr-1",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.setForm(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }